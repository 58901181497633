import React, { ReactNode, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import ClassNames from 'classnames';

import { useDarkMode } from 'hooks/useDarkMode';
import { pluck } from 'utils/object';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import colours from 'css/base/_colours.module.scss';

type ConditionalProps =
  | ({ to?: undefined } & Pick<HTMLAttributes<HTMLButtonElement>, 'tabIndex'>)
  | Pick<LinkProps, 'to' | 'tabIndex'>;

export type IconButtonProps = {
  icon: string;
  className?: string;
  tooltip?: React.ReactNode;
  ariaLabel: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  colour?: 'dark_blue' | 'blue' | 'primary';
  fontSize?: string; // Added for legacy support, since we use !important in the styled component
  fontWeight?: string; // Added for legacy support, since we use !important in the styled component
  children?: ReactNode;
} & ConditionalProps;

export default function IconButton({
  icon,
  to,
  className,
  tooltip,
  ariaLabel,
  onClick,
  colour = 'dark_blue',
  fontSize = '18px',
  fontWeight = 'bold',
  children,
  tabIndex,
  disabled
}: IconButtonProps) {
  const darkMode = useDarkMode();
  const commonProps = {
    className: ClassNames('icon_button', icon, className, { disabled, dark: darkMode }),
    'aria-label': ariaLabel,
    onClick: disabled
      ? () => {
          return;
        }
      : onClick,
    colour,
    fontSize,
    fontWeight,
    tabIndex
  };

  if (to) {
    return (
      <LinkIcon to={to} {...commonProps}>
        {tooltip}
        <ConditionalRender predicate={children}>{children}</ConditionalRender>
      </LinkIcon>
    );
  }

  return (
    <ButtonIcon type="button" {...commonProps}>
      {tooltip}
      <ConditionalRender predicate={children}>{children}</ConditionalRender>
    </ButtonIcon>
  );
}

const IconStyles = css<Pick<IconButtonProps, 'colour' | 'fontSize' | 'fontWeight'>>`
  position: relative;
  padding: 8px;
  border-radius: 50%;
  font-size: ${pluck('fontSize')} !important;
  font-weight: ${pluck('fontWeight')} !important;
  transition: all 0.1s ease-in-out;

  ${({ colour, theme }) => {
    switch (colour) {
      case 'blue':
        return css`
          color: ${theme.colours.primaryColour};
        `;
      case 'primary':
        return css`
          color: var(--text-accent-secondary-base);
        `;
      default:
        break;
    }
  }}

  &.icon_close {
    font-size: 16px;
  }

  &:hover {
    .tooltip_wrapper {
      display: block;
    }

    background-color: ${colours.lightBgColour};

    &.dark {
      background-color: ${colours.darkerBgColour};
    }
  }
`;

const LinkIcon = styled(Link)`
  ${IconStyles}
`;

const ButtonIcon = styled.button`
  ${IconStyles}
`;
