import React from 'react';
import ClassNames from 'classnames';
import styled, { css, keyframes } from 'styled-components';

import { Member } from 'types';

import Tooltip from 'components/tooltip';

import Avatar from 'v2/components/ui/atoms/Avatar';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

type MessagePromptProps = {
  messagePrompt?: string;
  primaryMemberDetails?: Partial<Member>;
  appearance: 'preview' | 'section' | 'chat';
};

const MESSAGE_PROMPT_GUIDE =
  "Preview your message prompt here. Candidates will see the prompt both on the positions and in the chat when they're applying. The prompt will be from the primary contact assigned to the position.";

export default function MessagePrompt({
  messagePrompt,
  primaryMemberDetails,
  appearance
}: MessagePromptProps) {
  const { memberName, photoURL, lastActive } = primaryMemberDetails || {};
  const sender = `Message prompt from ${memberName}`;

  const isPreview = appearance === 'preview';
  const isSection = appearance === 'section';

  return (
    <ConditionalRender predicate={isPreview || messagePrompt}>
      <div className={ClassNames({ section: isSection })}>
        <ConditionalRender predicate={isSection}>
          <h3 className="sub_title">{sender}</h3>
        </ConditionalRender>
        <MessagePromptCont appearance={appearance}>
          <Avatar
            photoURL={photoURL}
            firstname={memberName}
            width={48}
            resizePhotoDimensions={{ width: 100 }}
            lastActive={lastActive}
          />
          <MessagePromptRight>
            <ConditionalRender predicate={!isSection}>
              <div className="sender">
                <b>{sender}</b>
                <ConditionalRender predicate={isPreview}>
                  <span className="icon_help">
                    <Tooltip
                      text="The prompt will be from the person set as the primary contact of the position."
                      width="240px"
                    />
                  </span>
                </ConditionalRender>
              </div>
            </ConditionalRender>
            <MessagePromptWrapper className="message_prompt_wrapper" hasParentBgColor={isPreview}>
              {messagePrompt || MESSAGE_PROMPT_GUIDE}
            </MessagePromptWrapper>
          </MessagePromptRight>
        </MessagePromptCont>
      </div>
    </ConditionalRender>
  );
}

const fadeIn = keyframes`
  0% { 
    opacity: 0; 
    margin-top: 100px 
  }
  100% { 
    opacity: 1; 
    margin-top: 0 
  }
`;

const MessagePromptCont = styled.div<Pick<MessagePromptProps, 'appearance'>>`
  display: flex;
  gap: 6px;
  border-radius: 1rem;

  ${({ appearance, theme: { bg } }) => {
    switch (appearance) {
      case 'preview':
        return css`
          padding: 1.25rem;
          background: ${bg};
        `;
      case 'chat':
        return css`
          margin-bottom: 1.25rem;
          animation: ${fadeIn} 0.5s ease-in-out 1s both;
        `;
      default:
        break;
    }
  }}
`;

const MessagePromptRight = styled.div`
  ${({ theme: { typography } }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1 0 0;

    .sender {
      display: flex;
      align-items: center;
      font-size: ${typography.mobile};

      .icon_help {
        font-size: ${typography.mobile};
        margin-left: 0.25rem;
      }
    }
  `}
`;

const MessagePromptWrapper = styled.div<{ hasParentBgColor?: boolean }>`
  padding: 0.75rem 1rem;
  border-radius: 1.5rem;
  border-top-left-radius: 0.5rem;
  background: ${({ hasParentBgColor, theme: { darkMode, colours, container } }) => {
    if (hasParentBgColor) return container;
    return darkMode ? colours.darkerBgColour : '#F1F5F8';
  }};
`;
