import { CANDIDATE_SEARCH_FILTERS } from 'consts/candidate/positionSearch';

import {
  CompanyIndustry,
  CompanySize,
  CompanyType,
  PlatformStatistic,
  RemoteOption
} from '@cohiretech/common-types';

import { getDefaultSorting } from 'v2/services/candidate';
import { SearchItem } from 'v2/services/fetchers/candidate/search';
import { getPercentage } from 'utils';

export const defaultSorting = getDefaultSorting({
  isPublicSearch: true,
  isAgency: false
});

export enum CompanyTabQueries {
  Tech = 'tech',
  Startups = 'start-ups',
  Scaleups = 'scale-ups',
  Enterprise = 'enterprise',
  RemoteFirst = 'remote-first',
  Hybrid = 'hybrid',
  AIPowered = 'ai-powered',
  FinTech = 'fintech',
  HealthTech = 'healthtech',
  EdTech = 'edtech',
  Analytics = 'analytics'
}

type CompanyCategory = {
  name: string;
  query: CompanyTabQueries;
  icon: string;
  category?: string;
  companyType: CompanyType;
  searchItems: SearchItem[];
};

export const companyCategories: CompanyCategory[] = [
  {
    name: 'Tech',
    query: CompanyTabQueries.Tech,
    icon: 'icon_hub',
    category: 'popularCompany',
    companyType: CompanyType.Direct,
    searchItems: [defaultSorting]
  },
  {
    name: 'Start-ups',
    query: CompanyTabQueries.Startups,
    icon: 'icon_rocket',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      { attribute: CANDIDATE_SEARCH_FILTERS.companySize.attribute, value: CompanySize['1to10'] },
      { attribute: CANDIDATE_SEARCH_FILTERS.companySize.attribute, value: CompanySize['11to50'] }
    ]
  },
  {
    name: 'Scale-ups',
    query: CompanyTabQueries.Scaleups,
    icon: 'icon_scaleup',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      { attribute: CANDIDATE_SEARCH_FILTERS.companySize.attribute, value: CompanySize['51to200'] },
      { attribute: CANDIDATE_SEARCH_FILTERS.companySize.attribute, value: CompanySize['201to500'] }
    ]
  },
  {
    name: 'Enterprise',
    query: CompanyTabQueries.Enterprise,
    icon: 'icon_company',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      {
        attribute: CANDIDATE_SEARCH_FILTERS.companySize.attribute,
        value: CompanySize['501to1000']
      },
      {
        attribute: CANDIDATE_SEARCH_FILTERS.companySize.attribute,
        value: CompanySize['1001to5000']
      },
      { attribute: CANDIDATE_SEARCH_FILTERS.companySize.attribute, value: CompanySize['5001+'] }
    ]
  },
  {
    name: 'Remote-first',
    query: CompanyTabQueries.RemoteFirst,
    icon: 'icon_earth',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      {
        attribute: CANDIDATE_SEARCH_FILTERS.remoteWorking.attribute,
        value: RemoteOption.Remote
      }
    ]
  },
  {
    name: 'Hybrid',
    query: CompanyTabQueries.Hybrid,
    icon: 'icon_hybrid',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      {
        attribute: CANDIDATE_SEARCH_FILTERS.remoteWorking.attribute,
        value: RemoteOption.Hybrid
      }
    ]
  },
  {
    name: 'AI-Powered',
    query: CompanyTabQueries.AIPowered,
    icon: 'icon_sparkles',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      {
        attribute: CANDIDATE_SEARCH_FILTERS.industry.attribute,
        value: CompanyIndustry.MACHINE_LEARNING_AND_AI
      }
    ]
  },
  {
    name: 'FinTech',
    query: CompanyTabQueries.FinTech,
    icon: 'icon_fintech',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      {
        attribute: CANDIDATE_SEARCH_FILTERS.industry.attribute,
        value: CompanyIndustry.FINTECH
      }
    ]
  },
  {
    name: 'EdTech',
    query: CompanyTabQueries.EdTech,
    icon: 'icon_knowledge',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      {
        attribute: CANDIDATE_SEARCH_FILTERS.industry.attribute,
        value: CompanyIndustry.ED_TECH
      }
    ]
  },
  {
    name: 'Analytics',
    query: CompanyTabQueries.Analytics,
    icon: 'icon_line_bars',
    companyType: CompanyType.Direct,
    searchItems: [
      defaultSorting,
      {
        attribute: CANDIDATE_SEARCH_FILTERS.industry.attribute,
        value: CompanyIndustry.ANALYTICS
      }
    ]
  }
];

export const getHomepageMetrics = (stats?: PlatformStatistic) => {
  return [
    {
      icon: 'icon_users',
      metric: '2,000+',
      description: 'users joining every week'
    },
    {
      icon: 'icon_briefcase',
      metric: '40,000+',
      description: 'open positions'
    },
    {
      icon: 'icon_conversation',
      metric: '3 million+',
      description: 'messages exchanged'
    },
    {
      icon: 'icon_direct',
      metric: getPercentage(stats?.averageResponseRate || 0.8, true),
      description: 'company response rate'
    }
  ];
};
