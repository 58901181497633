import React, { useMemo } from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { getTimeAgo } from 'utils';
import { getImageDimensions, getResizedImageURL } from 'v2/services/image';
import { getIsDarkMode_FIXME } from 'cookieManager';
import { isMobileWidth, mobile } from 'responsiveConfig';

import { generateInsightsURL } from 'views/landing/insights/helpers';

import Button from 'components/button';
import MultiAvatarPhotos from 'components/multiavatarphotos';

import colours from 'css/base/_colours.module.scss';

type Props = {
  discussion: {
    discussionPoint: string;
    uid: string;
    topic: string;
    firstPublicationDate: string;
    involvedPeople: {
      photoURL: string;
      firstname: string;
      lastname: string;
    }[];
  };
  maxPhotosShown?: number;
  photosWidth?: number;
};

type StyledProps = {
  darkMode: boolean;
};

function InsightsDiscussionCard({ discussion, maxPhotosShown = 3, photosWidth = 40 }: Props) {
  const { discussionPoint, uid, topic, firstPublicationDate, involvedPeople } = discussion;
  const darkMode = getIsDarkMode_FIXME();
  const location = useLocation();

  const mostRecentPeopleFirstWithResizedPhotos = useMemo(() => {
    return involvedPeople?.reverse().map(person => {
      return person.photoURL?.startsWith('https://cord-image-bucket')
        ? {
            ...person,
            photoURL: getResizedImageURL(person.photoURL, getImageDimensions('no-crop-logo'))
          }
        : person;
    });
  }, [involvedPeople]);

  return (
    <Wrapper className={ClassNames('insights_discussion_item')} darkMode={darkMode}>
      <h3 className="title">{discussionPoint}</h3>
      <h4 className="subtitle">{`Discussion started ${getTimeAgo(firstPublicationDate)}.`}</h4>

      <div className="bottom_section">
        <MultiAvatarPhotos
          photosData={mostRecentPeopleFirstWithResizedPhotos}
          photosWidth={isMobileWidth ? 30 : photosWidth}
          maxPhotosShown={maxPhotosShown}
        />

        <div className="cta_button">
          <Button
            text="See discussion"
            link={{
              pathname: generateInsightsURL({ contentType: 'discussion', topic, uid }),
              search: location.search,
              state: { previousPath: location.pathname }
            }}
            buttonStyle="fill_blue"
            icon="icon_arrow_right"
            iconPosition="right"
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default InsightsDiscussionCard;

const Wrapper = styled.div<StyledProps>`
  border-radius: 20px;
  background-color: ${props => (props.darkMode ? colours.darkBgColour : colours.lightBgColour)};
  padding: 25px;
  box-sizing: border-box;

  .title {
    margin: 0;
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  .subtitle {
    white-space: pre-line;
  }

  .bottom_section {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .multi_avatar_photos {
      margin-right: auto;
    }

    .cta_button {
      margin-left: auto;
    }
  }

  @media only screen and (max-width: ${mobile}) {
    .subtitle {
      font-size: 14px;
    }

    .bottom_section {
      flex-direction: column;
      row-gap: 1rem;

      .cta_button {
        width: 100%;
        margin-left: 0;
      }
    }
  }
`;
