import {
  ATTRIBUTE_DEPENDENCY_MAP,
  ATTRIBUTE_PAIR_MAP,
  CANDIDATE_SEARCH_FILTERS,
  LAST_ACTIVE_FILTERS,
  REMOTE_LOCATION_FILTERS,
  REMOTE_LOCATION_OPTIONS,
  WORKING_TIMEZONE_FILTERS
} from 'consts/candidate/positionSearch';

import { RemoteOption, UserType } from '@cohiretech/common-types';

import { getRemoteOptions } from 'fetcher';
import { CandidateSearchFilterKey, CandidateSearchFilterOptions } from 'store';
import { equalsPickByKey, isEmpty, isUndefined } from 'utils/fn';
import {
  AddSearchItem,
  RequiredToOptional,
  SearchItem,
  SearchItemToRemove,
  SearchItems
} from 'types';
import { last, removeItemByIndex } from 'utils/array';
import { isPartialMatch } from 'utils/object';

import { FilterOptions, FilterOptionsGroups } from 'components/expandablefilter';
import { RangeFilterProps } from 'components/rangefilter';

import { handleSearchItemsAdd } from 'v2/components/PositionSearchResults/PositionSearchResults.helper';

const { salary, companySize, remoteWorking, industry, boundNorth, lastActiveValue } =
  CANDIDATE_SEARCH_FILTERS;

const ANYWHERE_VALUE = '*';

export const ANYWHERE_OPTIONS: SearchItems = REMOTE_LOCATION_FILTERS.map(filter => ({
  label: 'Anywhere',
  value: ANYWHERE_VALUE,
  attribute: CANDIDATE_SEARCH_FILTERS[filter].attribute
}));

export const hasFilterWithAttribute = (
  filters: CandidateSearchFilterKey[],
  attribute: string | undefined
) => attribute && filters.some(filter => CANDIDATE_SEARCH_FILTERS[filter].attribute === attribute);

export const checkIfTextFilter = (attribute?: string) =>
  hasFilterWithAttribute(['keyword', 'companyName'], attribute);

export const checkIfSalaryFilter = (attribute?: string) => attribute === salary.attribute;

export const checkIfWorkingTimezoneFilter = (attribute?: string) =>
  hasFilterWithAttribute(WORKING_TIMEZONE_FILTERS, attribute);

export const checkIfRemoteLocationFilter = (attribute?: string) =>
  hasFilterWithAttribute(REMOTE_LOCATION_FILTERS, attribute);

export const checkIfRemoteFilter = (attribute?: string) =>
  attribute === remoteWorking.attribute || checkIfRemoteLocationFilter(attribute);

export const checkIfLastActiveFilter = (attribute?: string) =>
  hasFilterWithAttribute(LAST_ACTIVE_FILTERS, attribute);

export const checkIfRemoteWorkType = (searchItem: SearchItemToRemove) =>
  isPartialMatch({
    value: RemoteOption.Remote,
    attribute: remoteWorking.attribute
  })(searchItem);

export const checkIfAnywhere = (searchItem: SearchItemToRemove) =>
  isPartialMatch({ value: ANYWHERE_VALUE })(searchItem);

export const getSelectedLastActive = (selectedItems: FilterOptions) =>
  equalsPickByKey(selectedItems)('attribute')(lastActiveValue.attribute)?.label;

const addAttribute =
  (attribute: SearchItem['attribute']) =>
  (searchItem: RequiredToOptional<SearchItem, 'attribute'>) => ({ ...searchItem, attribute });

export const getRemoteFilterOptions = () => {
  const remoteFilterOptions = getRemoteOptions(UserType.Candidate).map(
    addAttribute(remoteWorking.attribute)
  );
  const remoteFilterOptionsGroups: FilterOptionsGroups = [{ options: remoteFilterOptions }];

  const { value, attribute } = remoteFilterOptions.find(checkIfRemoteWorkType)!;

  remoteFilterOptionsGroups.push({
    title: 'Remote locations',
    options: [ANYWHERE_OPTIONS[0]].concat(REMOTE_LOCATION_OPTIONS),
    dependency: { value, attribute }
  });

  return remoteFilterOptionsGroups;
};

export const getSearchItemIndex = (
  searchItems: SearchItems,
  { label, value, attribute }: SearchItem
) =>
  searchItems.findIndex(item => {
    const basicCheck = item.attribute === attribute && item.value === value;
    return attribute === lastActiveValue.attribute && label ? item.label === label : basicCheck;
  });

export const handleRemoteItemAdd = (searchItems: SearchItems, newFilter: SearchItem) => {
  let preFilteredItems = searchItems;
  let newFilters: SearchItem | SearchItems = newFilter;

  if (checkIfRemoteLocationFilter(newFilter.attribute)) {
    const isAnywhere = checkIfAnywhere(newFilter);

    // If "anywhere" is selected, remove all other remote locations.
    // If any other remote location is selected, remove "anywhere".
    preFilteredItems = REMOTE_LOCATION_FILTERS.reduce((acc, filter) => {
      const itemToRemove = {
        attribute: CANDIDATE_SEARCH_FILTERS[filter].attribute,
        value: isAnywhere ? undefined : ANYWHERE_VALUE
      };

      return getSearchItemsOnRemove(acc, itemToRemove);
    }, preFilteredItems);

    if (isAnywhere) newFilters = ANYWHERE_OPTIONS;
  }

  if (checkIfRemoteWorkType(newFilter)) newFilters = [newFilter, ...ANYWHERE_OPTIONS];

  return handleSearchItemsAdd(preFilteredItems, newFilters);
};

export const getSearchItemsOnRemove = (
  currentItems: SearchItems,
  itemToRemove: RequiredToOptional<SearchItemToRemove, 'value'>
) => {
  const { value, attribute } = itemToRemove;
  const pairAttr = ATTRIBUTE_PAIR_MAP[attribute];
  const dependentAttrs = ATTRIBUTE_DEPENDENCY_MAP[attribute] || [];

  // Remove all search items that have the attribute
  if (isUndefined(value)) {
    return currentItems.filter(
      item => ![attribute, pairAttr, ...dependentAttrs].includes(item.attribute)
    );
  }

  const searchItem = itemToRemove as SearchItem;
  const searchItemIdx = getSearchItemIndex(currentItems, searchItem);
  const searchItems = removeItemByIndex(currentItems, searchItemIdx);

  const isLastActiveFilter = checkIfLastActiveFilter(attribute);
  const isRemoteWorkType = checkIfRemoteWorkType(searchItem);
  const isAnywhere = checkIfAnywhere(searchItem);
  const isBoundNorthFilter = attribute === boundNorth.attribute;

  return searchItems.filter(item => {
    if (isLastActiveFilter) return item.attribute !== pairAttr;
    if (isRemoteWorkType) return !dependentAttrs.includes(item.attribute);
    if (isAnywhere) return !checkIfAnywhere(item);
    if (isBoundNorthFilter) return !item.attribute.includes('locationBound');
    return true;
  });
};

export const getSearchItemsOnOperatorUpdate = (
  searchItems: SearchItems,
  oldAttribute: string,
  newAttribute: string
) =>
  searchItems.map(item =>
    item.attribute === oldAttribute ? { ...item, attribute: newAttribute } : item
  );

// searchItems of previous saved searches have label with filter name.
export const parseSearchItemLabel = (label: SearchItem['label']) => {
  const substrings = label.split(': ');
  return substrings[1] || substrings[0];
};

export const getLabelWithFilterTitle = (label: string, attribute: string) => {
  switch (attribute) {
    case salary.attribute:
      return `Min: ${label}`;
    case companySize.attribute:
      return `Company Size: ${label}`;
    case industry.attribute:
      return `Industry: ${label}`;
    default:
      return label;
  }
};

const UPPER_LIMIT_MIN_SALARY = 200000;

export const getMinSalaryRangeProps = (
  options: CandidateSearchFilterOptions['salaryOptions'],
  addSearchItem: AddSearchItem
): Omit<RangeFilterProps, 'range' | 'filterName'> => {
  const salaryOptions = options.options?.filter(({ value }) => value <= UPPER_LIMIT_MIN_SALARY);

  if (isEmpty(salaryOptions)) return {} as RangeFilterProps;

  const minValue = salaryOptions[0].value;
  const maxValue = last(salaryOptions)!.value;

  return {
    type: 'salary',
    minValue,
    maxValue,
    suffix: '+',
    step: salaryOptions[1].value - minValue,
    onSelect: min => {
      const label = salaryOptions.find(({ value }) => value === min)!.label;

      addSearchItem(salary.attribute, label, min);
    },
    styledProps: { hideActiveTrack: true, hideMaxLabel: true, minLabelIcon: 'icon_salary' }
  };
};
