import React from 'react';
import styled from 'styled-components';
import ClassNames from 'classnames';
import { NavLink as Link, useLocation } from 'react-router-dom';

import { isEmpty } from 'utils';
import { getIsDarkMode_FIXME } from 'cookieManager';

import {
  generateInsightsURL,
  getInsightsArticleDuration,
  useIsHiringInsightsPage
} from 'views/landing/insights/helpers';

import Tags from 'components/tags';
import LabelWithIcon from 'components/labelwithicon';

import Play from 'images/play.png';

const InsightsArticleItem = ({ article, style }: $TSFixMe) => {
  const {
    title,
    topic,
    personName,
    previewPhoto,
    duration,
    subtitle,
    uid,
    tags,
    type,
    youtubeVideo
  } = article;
  const articleTypeIcon = isEmpty(youtubeVideo) ? 'icon_article' : 'icon_video';
  const personNameText = !isEmpty(personName) ? personName?.text : '';
  const darkMode = getIsDarkMode_FIXME();
  const location = useLocation();
  const [isHiringInsights] = useIsHiringInsightsPage();

  return (
    <InsightCardItem
      className={ClassNames('square_card_item', 'insights_article_item', 'clickable', {
        dark: darkMode
      })}
      style={style}
      to={{
        pathname: generateInsightsURL({ contentType: type, topic, uid }, isHiringInsights),
        search: location.search,
        state: { previousPath: location.pathname }
      }}
    >
      <ArticleImageWrapper>
        {articleTypeIcon === 'icon_video' && (
          <PlayIcon
            className="play_image"
            width="75"
            height="77"
            src={Play}
            alt="Play Icon"
            loading="lazy"
          />
        )}
        <img
          className="cover_photo"
          src={previewPhoto?.url}
          alt={previewPhoto?.alt || `Cover of ${title?.text} article`}
          loading="lazy"
          width="390"
          height="250"
        />
      </ArticleImageWrapper>
      <ArticleDetails className="article_details">
        <ArticleTopic className="article_details_topic lightblue_text">
          {personNameText || topic}
        </ArticleTopic>
        <h3>{title?.text}</h3>
        <p className="article_subtitle">{subtitle?.text}</p>
        {!isEmpty(tags) && (
          <Tags tags={tags?.filter((tag: $TSFixMe) => tag !== 'Featured')} size="small" noWrap />
        )}
        <LabelWithIcon icon={articleTypeIcon} text={getInsightsArticleDuration(duration)} />
      </ArticleDetails>
    </InsightCardItem>
  );
};

export default InsightsArticleItem;

const ArticleImageWrapper = styled.div`
  position: relative;
`;

const PlayIcon = styled.img`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const InsightCardItem = styled(Link)`
  vertical-align: middle;
  transition: all 0.2s ease-in-out !important;
`;

const ArticleDetails = styled.div`
  padding-top: 15px;
  min-height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  gap: 5px;

  h3 {
    margin: 0;
    overflow: hidden;
    display: -webkit-box; //allow ellipsis on line 2
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: pre-line;
    word-wrap: break-word;
  }

  .article_subtitle {
    margin: 0;
    overflow: hidden;
    display: -webkit-box; //allow ellipsis on line 2
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: pre-line;
    word-wrap: break-word;
  }

  .tags_wrapper {
    margin: 0;
  }

  .label_with_icon {
    margin-top: auto;
  }
`;

const ArticleTopic = styled.h4`
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;
  letter-spacing: 0.1px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
