// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'came... Remove this comment to see the full error message
import camelize from 'camelize';

import Prismic from '@prismicio/client';
import {
  HIRING_INSIGHTS_TOPICS_LIST,
  TECH_HUB_TOPICS_LIST,
  TECH_HUB_TYPES_LIST
} from '@cohiretech/common-types';

export const insightsEndpoint = process.env.REACT_APP_INSIGHTS_CMS!;
export const hiringInsightsEndpoint = process.env.REACT_APP_HIRING_INSIGHTS_CMS!;

const getClient = (isHiringInsights: boolean) =>
  isHiringInsights ? Prismic.client(hiringInsightsEndpoint) : Prismic.client(insightsEndpoint);

export const PRISMIC_RESPONSE_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure'
};

export const TYPES_LIST = TECH_HUB_TYPES_LIST;

const MERGED_TOPICS_LIST = [...TECH_HUB_TOPICS_LIST, ...HIRING_INSIGHTS_TOPICS_LIST];

export const INITIAL_ARTICLES_RESULTS_STATE = {
  loading: true,
  page: 1,
  nextPage: 2,
  articles: [],
  articlesPerPage: 20,
  totalPages: 1,
  articlesCount: 0
};

export const getTopicsList = (isHiringInsights: boolean) =>
  isHiringInsights ? HIRING_INSIGHTS_TOPICS_LIST : TECH_HUB_TOPICS_LIST;

export const fetchInsightsAuthorByID = async (id: $TSFixMe, isHiringInsights: boolean) => {
  try {
    const client = getClient(isHiringInsights);
    // @ts-expect-error TS(2554) FIXME: Expected 2-3 arguments, but got 1.
    const response = await client.getByID(id);
    return { status: PRISMIC_RESPONSE_STATUS.SUCCESS, author: camelize(response) };
  } catch (error) {
    return { status: PRISMIC_RESPONSE_STATUS.FAILURE };
  }
};

const parsePath = (pathTitle: $TSFixMe) => {
  if (!pathTitle) return '';
  return pathTitle?.replace(/ & /g, '-')?.replace(/ /g, '-').toLowerCase();
};

export const getTopicPath = (topic: $TSFixMe) => {
  return MERGED_TOPICS_LIST.find(({ label }) => label === topic)?.value || parsePath(topic);
};
