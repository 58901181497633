import { CandidateSavedSearchInterface, CategorisedListingsDTO } from '@cohiretech/common-types';

import { candidateSearchFilters } from 'fetcher';
import { KeywordOptions, ObjectValues } from 'types';
import { getFilterOptions } from 'utils';

import { SearchOptions } from 'v2/components/ui/molecules/SearchOptionList';

export type CandidateSearchFilterOptions = Awaited<ReturnType<typeof getFilterOptions>>;

export type CandidateSavedSearch = Pick<
  Partial<CandidateSavedSearchInterface>,
  'id' | 'searchName' | 'searchParam' | 'subscribe' | 'subscribeFrequency'
>;

export type CandidateSavedSearchNotifications = Pick<
  CandidateSavedSearchInterface,
  'subscribe' | 'subscribeFrequency'
>;

export type UpdateCandidateSavedSearchParams = {
  id: CandidateSavedSearchInterface['id'];
  searchName?: CandidateSavedSearchInterface['searchName'];
  searchParam?: ObjectValues<CandidateSavedSearchInterface['searchParam']>;
  notificationSettings?: CandidateSavedSearchNotifications;
};

export type TriggerType = 'save' | 'get-notified';

export type SaveSearchPopupInfo = { show: boolean; triggerType: TriggerType };

export type ToggleSaveSearchPopupParams = Pick<
  CandidateSearchState,
  'currentSearch' | 'saveSearchPopup'
>;

export type SearchCategory = keyof CategorisedListingsDTO;

export type SearchOptionsByCategory = { [key in SearchCategory]: SearchOptions };

export type PositionSearchOptions = {
  byCategory: SearchOptionsByCategory;
  keyword: KeywordOptions;
};

export type CandidateSearchState = {
  currentSearch?: CandidateSavedSearch;
  searchOptions: PositionSearchOptions;
  filterOptions: CandidateSearchFilterOptions;
  savedSearches: CandidateSavedSearchInterface[];
  saveSearchPopup?: SaveSearchPopupInfo;
  savedSearchToDelete?: CandidateSavedSearchInterface;
};

export const SEARCH_CATEGORY_INFO: { [key in SearchCategory]: { icon: string; label: string } } = {
  jobTitles: { icon: 'icon_briefcase', label: 'Job title' },
  skills: { icon: 'icon_skill', label: 'Skill' },
  companies: { icon: 'icon_company', label: 'Company' },
  industries: { icon: 'icon_industry', label: 'Industry' }
};

export type CandidateSearchFilterKey = keyof typeof candidateSearchFilters;

export const CATEGORY_TO_FILTER_KEY_MAP: {
  [key in SearchCategory]: CandidateSearchFilterKey;
} = {
  jobTitles: 'jobTitle',
  skills: 'skill',
  companies: 'companyName',
  industries: 'industry'
};

export const getFilterAttributeByCategory = (category?: SearchCategory) => {
  const key: CandidateSearchFilterKey =
    (category && CATEGORY_TO_FILTER_KEY_MAP[category]) || 'keyword';
  return candidateSearchFilters[key].attribute;
};

export const findSavedSearchById = (savedSearches: CandidateSavedSearchInterface[], id: number) => {
  const index = savedSearches.findIndex(search => search.id === id);

  return { index, value: savedSearches[index] };
};
