import React, { useState, createContext, PropsWithChildren } from 'react';

import { Member, PrimaryMember } from 'types';
import { useCompanyProfile, useCompanyUser } from 'store';

export type PositionEditContextState = {
  showOTEField: boolean;
  primaryMemberDetails?: Member;
  setShowOTEField: (show: boolean) => void;
  handlePrimaryMemberChange: (primaryMemberID: PrimaryMember) => void;
};

export const PositionEditContext = createContext<PositionEditContextState | null>(null);

const PositionEditProvider = ({ children }: PropsWithChildren<{}>) => {
  const { members } = useCompanyProfile() || {};
  const { accountID } = useCompanyUser() || {};

  const [showOTEField, setShowOTEField] = useState(false);
  const [primaryMemberDetails, setPrimaryMemberDetails] = useState<Member>();

  const handlePrimaryMemberChange = (primaryMemberID: PrimaryMember) => {
    // primaryMemberID may not exist or exist but a deleted account.
    const [primaryMember, currentUser] = [primaryMemberID, accountID].map(memberID =>
      members?.find(({ id }) => id === memberID)
    );

    setPrimaryMemberDetails(primaryMember || currentUser);
  };

  return (
    <PositionEditContext.Provider
      value={{ showOTEField, primaryMemberDetails, setShowOTEField, handlePrimaryMemberChange }}
    >
      {children}
    </PositionEditContext.Provider>
  );
};

export default PositionEditProvider;
