import axios from 'axios';

import {
  CompanyTypeSearchQueryParam,
  InsightsTypes,
  SearchCategoryViewTypeEnum
} from '@cohiretech/common-types';

import { candidateSearchFilters } from 'fetcher';
import { getSearchTerms, parseSearchItemsIntoTags } from 'utils';

import { getRepoType } from 'views/landing/insights/helpers';

const PUBLIC_SEARCH = process.env.REACT_APP_PUBLIC_SEARCH!;
const CANDIDATE_SEARCH = process.env.REACT_APP_CANDIDATE_SEARCH!;

export type SearchItem = {
  attribute: string;
  label?: string;
  value: number | string | boolean;
};

export type SearchParams = {
  searchItems: SearchItem[];
  page?: number;
  category?: string;
  ui?: string;
  passiveSearch: boolean;
  publicSearch?: boolean;
  viewType?: SearchCategoryViewTypeEnum | InsightsTypes;
  companyType?: CompanyTypeSearchQueryParam;
  isHiringInsights?: boolean;
  signal?: AbortSignal;
};

export const search = async ({
  searchItems,
  page,
  category,
  ui,
  passiveSearch,
  publicSearch,
  viewType,
  companyType,
  isHiringInsights = false,
  signal
}: SearchParams) => {
  const requestId = `${publicSearch ? 'public' : 'private'}_search_request_${viewType}_${
    searchItems.length
  }_${companyType}`;
  const params = {
    page,
    category,
    passiveSearch,
    view: viewType,
    companyType,
    CMSResource: getRepoType(isHiringInsights)
  };

  if (ui) (params as $TSFixMe).ui = ui;

  const parsedSearchItems = await parseSearchItemsIntoTags(searchItems, viewType, isHiringInsights);

  const stringParameters = [
    candidateSearchFilters.previouslyDealtWith.attribute,
    candidateSearchFilters.lastActiveValue.attribute,
    candidateSearchFilters.lastActiveType.attribute,
    candidateSearchFilters.salary.attribute,
    candidateSearchFilters.salaryVisible.attribute,
    candidateSearchFilters.sortBy.attribute,
    candidateSearchFilters.hidden.attribute,
    candidateSearchFilters.lowerTimeOffset.attribute,
    candidateSearchFilters.upperTimeOffset.attribute,
    candidateSearchFilters.workEligibility.attribute
  ];

  const searchTermsObj = { ...params, ...getSearchTerms(parsedSearchItems, stringParameters) };

  try {
    const { data } = await axios.get(publicSearch ? PUBLIC_SEARCH : CANDIDATE_SEARCH, {
      params: searchTermsObj,
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ params: { externalCompanies: b... Remove this comment to see the full error message
      requestId,
      signal
    });
    return (data as $TSFixMe).data;
  } catch (error: any) {
    return {
      status: axios.isCancel(error) ? 'cancelled' : 'failure',
      message: error?.response?.data,
      values: [],
      resultsCount: 0
    };
  }
};
