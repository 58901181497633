import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import LabelToggle from 'v2/components/ui/molecules/LabelToggle';
import { Ellipsis } from 'v2/components/ui/styles';

import { FilterOption } from '../..';

type FilterLabelProps = {
  visible: boolean;
} & Omit<FilterOption, 'value'>;

export default function FilterLabel({ label, visible, attribute, description }: FilterLabelProps) {
  const { labelTitle, addSearchItem, removeSearchItem } = useExpandableFilter();

  if (!description) return <>{label}</>;

  if (typeof description === 'string') {
    return (
      <>
        {label}
        <FilterLabelDesc key="desc" className="description">
          {description}
        </FilterLabelDesc>
      </>
    );
  }

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!visible) {
      setActive(false);
    }
  }, [visible]);

  const handleToggle = () => {
    if (!active) {
      addSearchItem!(attribute!, `${labelTitle} ${description.label}`, description.value);
    } else removeSearchItem!(attribute!, description.value);
    setActive(prev => !prev);
  };

  return (
    <>
      {label}
      <ConditionalRender predicate={visible}>
        <LabelToggle
          title={description.label}
          active={active}
          onToggle={handleToggle}
          description="Include temp. eligible"
        />
      </ConditionalRender>
    </>
  );
}

const FilterLabelDesc = styled.span`
  &.description {
    flex: 1;
    ${Ellipsis}
  }
`;
