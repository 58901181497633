import React from 'react';
import styled from 'styled-components';

import { candidateSearchFilters } from 'fetcher';
import { isEmpty } from 'utils';

import { useExpandableFilter } from 'components/expandablefilter/ExpandableFilter.context';
import LocationInput from 'components/locationinput';
import SelectedItems from 'components/selecteditems';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

const LOCATION_ATTRIBUTE = candidateSearchFilters.location.attribute;

export default function LocationList() {
  const { isOpen, selectedItems, addSearchItem, removeSearchItem } = useExpandableFilter();
  const hasSelection = !isEmpty(selectedItems);

  return (
    <LocationPicker className="location_list" hasSelection={hasSelection}>
      <LocationInput
        onSelect={locationName => addSearchItem!(LOCATION_ATTRIBUTE, locationName, locationName)}
        clearInputOnPlaceSelected
        icon="icon_map"
        focus={isOpen}
      />
      <ConditionalRender predicate={hasSelection}>
        <SelectedItems
          selectedItems={selectedItems || []}
          size="xsmall"
          rounded
          removeItem={item => removeSearchItem?.(LOCATION_ATTRIBUTE, item)}
        />
      </ConditionalRender>
    </LocationPicker>
  );
}

const LocationPicker = styled.div<{ hasSelection: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .location_input_wrapper {
    border: 0;
    padding: 0;
  }

  // To adjust .filter_content padding
  ${({ hasSelection }) => !hasSelection && 'margin-bottom: -0.5rem;'}

  .selected_items {
    padding: 0;

    .icon_close {
      line-height: normal;
    }
  }
`;
