import { useLocation } from 'react-router';
import { getTopicPath, TYPES_LIST } from 'prismic-configuration';

import { CMSResourceEnum, SearchCategoryViewTypeEnum } from '@cohiretech/common-types';

import { capitalizeFirst } from 'utils/string';

// If used in class component you must pass in location
export const useIsHiringInsightsPage = (location = useLocation()): [boolean, string] => {
  const isHiringInsightsPage = location?.pathname.includes('/hiring-insights');
  const basePath = isHiringInsightsPage ? '/hiring-insights' : '/techhub';
  return [isHiringInsightsPage, basePath];
};

export const getInsightsArticleDuration = (duration: $TSFixMe) => {
  if (!duration) return null;
  return `${duration.toString()} minute${duration === 1 ? '' : 's'}`;
};

const insightsToHiringInsightsMap = (string = '') =>
  string?.replace('stories', 'videos').replace('Stories', 'Videos');
const hiringInsightsToInsightsMap = (string = '') =>
  string?.replace('videos', 'stories').replace('Videos', 'Stories');

export const singulariseInsightsType = (pluralContentType: $TSFixMe) => {
  const pluralContentTypeAfterMap = hiringInsightsToInsightsMap(pluralContentType);

  if (pluralContentTypeAfterMap === 'Stories') return 'Story';
  if (pluralContentTypeAfterMap === 'stories') return 'story';

  return `${pluralContentType.substring(0, pluralContentType.length - 1)}`;
};

export const pluraliseInsightsType = (contentType: $TSFixMe) => {
  if (contentType === 'Story') return 'Stories';
  if (contentType === 'story') return 'stories';

  return `${contentType}s`;
};

export const getDisplayedInsightsType = (
  contentType: string,
  isHiringInsights: boolean,
  capitalize = false
) => {
  // For hiring insights, we want to display 'videos' instead of 'stories'
  // For tech hub, we want to display 'interviews' instead of 'stories'
  let displayedType = pluraliseInsightsType(contentType);
  switch (contentType) {
    case SearchCategoryViewTypeEnum.Story:
      displayedType = isHiringInsights ? 'videos' : 'interviews';
      break;
    case SearchCategoryViewTypeEnum.EventRecording:
      displayedType = 'recordings';
      break;
    default:
      break;
  }

  return capitalize ? capitalizeFirst(displayedType) : displayedType;
};

export const generateInsightsURL = (articleData: $TSFixMe, isHiringInsights = false) => {
  const { contentType, topic, uid } = articleData;
  const basePath = isHiringInsights ? '/hiring-insights' : '/techhub';
  const pluralType = pluraliseInsightsType(contentType);
  const typeInURL = isHiringInsights ? insightsToHiringInsightsMap(pluralType) : pluralType;

  return `${basePath}/${getTopicPath(topic)}/${typeInURL}/${uid}`;
};

export const getInsightsHomepageBreadcrumb = (isHiringInsights: boolean) => {
  return isHiringInsights
    ? { label: 'Hiring insights', pathname: '/hiring-insights' }
    : { label: 'Tech hub', pathname: '/techhub' };
};

// Todo: this function is being used in different ways. Should refactor.
export const isInsightsType = (contentType: $TSFixMe) => {
  return TYPES_LIST.find(({ value }) => value?.replace('_', '-') === contentType);
};

export const isArticleOrStory = (contentType: $TSFixMe) => {
  return contentType === 'article' || contentType === 'story';
};

export const isEventRecording = (contentType: SearchCategoryViewTypeEnum | string) => {
  return contentType === SearchCategoryViewTypeEnum.EventRecording;
};

export const isClipOrDiscussion = (contentType: $TSFixMe) => {
  return (
    contentType === 'clip' ||
    contentType === 'insights_clip_item' ||
    contentType === 'discussion' ||
    contentType === 'insights_discussion_item'
  );
};

export const getRepoType = (isHiringInsights: boolean) => {
  return isHiringInsights ? CMSResourceEnum.CompanyInsights : CMSResourceEnum.CandidateInsights;
};

export const orderInsights = (insightsCategories: string[] | Object[], order: string[]) => {
  const orderedInsights = [];

  if (typeof insightsCategories[0] === 'string') {
    return order.filter((item: string) => insightsCategories.includes(item));
  }

  for (const insight of order) {
    orderedInsights.push(
      insightsCategories.find(
        ({
          // @ts-expect-error TS(2339) FIXME: Property 'contentType' does not exist on type 'Obj... Remove this comment to see the full error message
          contentType
        }) => contentType === insight
      )
    );
  }

  return orderedInsights;
};

export const showInsightsSlider = (
  contentType: string,
  resultsCount: number,
  isHiringInsights = false
) => {
  if (isHiringInsights && isArticleOrStory(contentType) && resultsCount > 0) return true;

  if (isArticleOrStory(contentType) && resultsCount > 2) return true;
  if (contentType === 'clip' && resultsCount > 3) return true;
  if (contentType === 'discussion' && resultsCount > 1) return true;
  if (contentType === 'event-recording' && resultsCount > 1) return true;
  return false;
};

// needs mobile slider consideration
export const showInsightsSliderArrows = (contentType: string, resultsCount: number) => {
  if (isArticleOrStory(contentType) && resultsCount > 0) return true;
  if (isClipOrDiscussion(contentType) && resultsCount > 2) return true;
  return false;
};
