import React, { HTMLInputTypeAttribute, ReactNode } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { emailFormat, passwordRequirementsChecker, passwordMeetsRequirements } from 'utils';
import { capitalizeSentences } from 'utils/string';
import { mobile } from 'responsiveConfig';

import ErrorMessage from 'components/errormessage';
import Loader from 'components/loader';

import SubmitArrowButton from 'v2/components/ui/atoms/SubmitArrowButton';
import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { InputWrapper } from 'v2/components/ui/styles';
import FieldErrorMessage from 'v2/components/ui/molecules/FieldErrorMessage';
import { StyledInput } from 'v2/components/ui/styles/FieldValidation';

import colours from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

type Props = {
  className?: string;
  loading?: boolean;
  value?: string | number;
  placeholder?: string;
  onChange?: $TSFixMeFunction;
  label?: $TSFixMe[] | string;
  note?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
  id?: string;
  attributes?: { [key: string]: string };
  inlineButton?: string;
  hidden?: boolean;
  removable?: boolean;
  onRemove?: $TSFixMeFunction;
  showNextArrow?: boolean;
  error?: string | string[];
  onEnter?: $TSFixMeFunction;
  clearOnEnter?: boolean;
  onChangeValidation?: boolean;
  description?: string;
  footnote?: ReactNode;
  validationFunc?: (value: string) => string[];
  noInputMessage?: string;
  formSubmitted?: boolean;
};

type State = $TSFixMe;

export default class Field extends React.Component<Props, State> {
  input: $TSFixMe;

  constructor(props: Props) {
    super(props);

    this.state = {
      errorMessage: props.error || '',
      value: props.value || '',
      passwordRequirements: passwordRequirementsChecker(''),
      showPassword: false,
      showErrorMessage: false
    };

    this.input = React.createRef();
  }

  validateInput(value: $TSFixMe) {
    const { type, validationFunc } = this.props;

    if (type === 'password') {
      const passwordRequirements = passwordRequirementsChecker(value);
      const passwordIsValid = passwordMeetsRequirements(passwordRequirements);

      this.setState({ passwordRequirements, errorMessage: passwordIsValid ? '' : ' ' });
    } else if (value.length === 0) this.setState({ errorMessage: "This input can't be blank." });
    else if (type === 'email' && !emailFormat.test(value)) {
      this.setState({ errorMessage: 'Please enter a valid email address' });
    } else if (validationFunc) {
      const error = validationFunc(value);
      this.setState({ errorMessage: error });
    } else this.setState({ errorMessage: '' });
  }

  onChange(value: $TSFixMe) {
    const { onChangeValidation, onChange } = this.props;

    if (onChangeValidation) this.validateInput(value);

    if (onChange) onChange(value);
    else this.setState({ value });
  }

  onEnter(value: $TSFixMe) {
    const { errorMessage } = this.state;
    const { onEnter, clearOnEnter } = this.props;

    if (onEnter && value && !errorMessage) {
      onEnter(value);
      if (clearOnEnter) this.setState({ value: '' });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { error, value, onChange } = this.props;

    if (error !== prevProps.error || (value !== prevProps.value && !onChange)) {
      this.setState({ errorMessage: error, value });
    }
  }

  getInputValue() {
    const value = this.input.current.value;

    return value;
  }

  render() {
    const { errorMessage, passwordRequirements, showPassword, showErrorMessage } = this.state;
    const {
      value,
      label,
      placeholder,
      type = 'text',
      attributes,
      disabled,
      id,
      className,
      inlineButton,
      hidden,
      removable,
      onRemove,
      showNextArrow,
      onChange,
      loading,
      onChangeValidation,
      note,
      description,
      footnote,
      noInputMessage,
      formSubmitted
    } = this.props;

    const fieldValue = onChange ? value : this.state.value;

    const showNoInput = Boolean(
      (formSubmitted || showErrorMessage) && noInputMessage && !fieldValue
    );

    return (
      <FieldWrapper className={ClassNames('field', className, { hidden })}>
        {label && (
          <Label className="question" htmlFor={id}>
            {label}
            {note && <div className="note">{note}</div>}
            {removable && <Remove onClick={onRemove}>Remove</Remove>}
            {description && <span className="description">{description}</span>}
          </Label>
        )}
        {showNextArrow ? (
          <InputWrapper className="input_wrapper" invalidInput={showNoInput}>
            <Input
              ref={this.input}
              type={type}
              id={id}
              placeholder={placeholder}
              {...attributes}
              value={fieldValue}
              onChange={({ target }) => this.onChange(target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') this.onEnter((e.target as $TSFixMe).value);
              }}
              disabled={disabled}
              invalidInput={showNoInput}
              onBlur={() => this.setState({ showErrorMessage: true })}
            />
            {loading && !errorMessage ? (
              <Loading className={ClassNames('input_loader', 'small')} />
            ) : (
              <SubmitArrowButton
                valid={this.state.value && !errorMessage}
                onSubmit={() => this.onEnter(this.state.value)}
              />
            )}
          </InputWrapper>
        ) : (
          <Input
            ref={this.input}
            type={showPassword ? 'text' : type}
            id={id}
            {...attributes}
            placeholder={placeholder}
            onKeyDown={e => {
              if (e.key === 'Enter') this.onEnter((e.target as $TSFixMe).value);
            }}
            value={fieldValue}
            onChange={({ target }) => this.onChange(target.value)}
            disabled={disabled}
            invalidInput={showNoInput}
            onBlur={() => this.setState({ showErrorMessage: true })}
          />
        )}
        {inlineButton && (
          <button
            type="button"
            className="link inline_link"
            onClick={() => this.onEnter(this.state.value)}
          >
            {inlineButton}
          </button>
        )}
        {!showNextArrow && type === 'password' && (
          <ShowPasswordIcon
            className={showPassword ? 'icon_hide' : 'icon_show'}
            onMouseDown={() => this.setState({ showPassword: true })}
            onMouseUp={() => this.setState({ showPassword: false })}
            onTouchStart={() => this.setState({ showPassword: true })}
            onTouchEnd={() => this.setState({ showPassword: false })}
          />
        )}
        <FieldErrorMessage show={showNoInput} message={noInputMessage} />
        <ConditionalRender predicate={footnote}>
          <em className="footnote">{footnote}</em>
        </ConditionalRender>
        {type === 'password' && onChangeValidation && (
          <InputRequirements>
            {passwordRequirements.map(({ label, check }: $TSFixMe) => (
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              <Requirement key={label} checked={check}>
                {check ? <span className="icon_tick" /> : <span className="dot" />}
                {label}
              </Requirement>
            ))}
          </InputRequirements>
        )}
        {errorMessage && !Array.isArray(errorMessage) && (
          <ErrorMessage>{capitalizeSentences(errorMessage)}</ErrorMessage>
        )}
        {errorMessage &&
          Array.isArray(errorMessage) &&
          errorMessage.map((err: string, indx: number) => (
            <ErrorMessage key={`${indx.toString()}-error`}>{capitalizeSentences(err)}</ErrorMessage>
          ))}
      </FieldWrapper>
    );
  }
}

const InputRequirements = styled.div`
  position: relative;
`;

const Requirement = styled.div`
  display: inline-block;
  margin-left: 15px;
  margin-top: 5px;
  font-size: 14px;
  color: ${colours.inputsColour};

  ${props =>
    (props as $TSFixMe).checked &&
    css`
      color: ${colours.primaryDarkShade};
    `}

  .dot {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: 5px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${colours.inputsColour};
  }

  .icon_tick {
    font-size: 14px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;
  }

  &:first-child {
    margin-left: 0;
  }

  @media only screen and (max-width: ${mobile}) {
    width: calc((100% - 15px) / 2);

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }
`;

const ShowPasswordIcon = styled.div`
  float: right;
  margin-top: -43px;
  padding: 10px;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
    color: ${colours.fontColourDarkShade};
  }
`;

const FieldWrapper = styled.div`
  padding: 12px 0;
  text-align: left;
  transition: all 0.2s ease-in-out;
  min-height: auto;
  max-height: 100vh;
  opacity: 1;
  box-sizing: border-box;

  &.hidden {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
  }

  .inline_link {
    position: absolute;
    right: 15px;
    bottom: 25px;
  }

  .footnote {
    display: inline-block;
    font-size: ${typography.small};
    padding-top: 0.25rem;
  }
`;

const Loading = styled(Loader)`
  position: absolute !important;
  right: 5px !important;
  left: inherit !important;
  width: 40px !important;
  top: 3px !important;

  .loader {
    border: 2px solid ${colours.fontColour} !important;
    border-top: 2px solid ${colours.lightFontColour} !important;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Remove = styled.span`
  color: ${colours.errorColour};
  float: right;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const Input = styled(StyledInput)`
  margin: 0;
  font-size: 18px;
`;
