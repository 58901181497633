import { SearchSortByEnum } from '@cohiretech/common-types';

import { CandidateSearchFilterKey } from 'store';
import { SearchItems } from 'types';

export const CANDIDATE_SEARCH_FILTERS = {
  orPrimarySkill: { label: 'Core skill', attribute: 'orPrimarySkill' },
  primarySkill: { label: 'Core skill', attribute: 'primarySkill' },
  orSkill: { label: 'Skill', attribute: 'orSkill' },
  skill: { label: 'Skill', attribute: 'skill' },
  techStack: { label: 'Tech stack/tooling', attribute: 'techStack' },
  orTechStack: { label: 'Tech stack/tooling', attribute: 'orTechStack' },
  jobTitle: { attribute: 'jobTitle' },
  jobType: { attribute: 'jobType' },
  seniority: { attribute: 'seniority' },
  salary: { label: 'Min', attribute: 'salary' },
  lowerTimeOffset: { label: 'Lower Timezone Offset', attribute: 'lowerTimeOffset' },
  upperTimeOffset: { label: 'Upper Timezone Offset', attribute: 'upperTimeOffset' },
  salaryVisible: {
    label: 'Exclude positions with undisclosed salaries',
    attribute: 'salaryVisible'
  },
  location: { attribute: 'location' },
  keyword: { attribute: 'keyword' },
  remoteWorking: { attribute: 'remote' },
  remoteLocationContinents: { attribute: 'remoteLocationContinents' },
  remoteLocationCountries: { attribute: 'remoteLocationCountries' },
  workEligibility: { label: 'Offer visa sponsorship', attribute: 'visaSponsorship' },
  previouslyDealtWith: {
    label: 'Include positions in conversation with',
    attribute: 'excludeDealtWith'
  },
  hidden: { label: 'Include positions marked as not right', attribute: 'includeHidden' },
  sortBy: { label: 'Sort by', attribute: 'sortBy' },
  lastActive: { label: 'Last active', attribute: 'lastActive' },
  companySize: { label: 'Company size', attribute: 'companySize' },
  companyName: { attribute: 'companyName' },
  industry: { attribute: 'industry' },
  boundSouth: { label: 'Selected map area', attribute: 'locationBoundSouth' },
  boundWest: { label: 'Selected map area', attribute: 'locationBoundWest' },
  boundNorth: { label: 'Selected map area', attribute: 'locationBoundNorth' },
  boundEast: { label: 'Selected map area', attribute: 'locationBoundEast' },
  sortByLat: { attribute: 'sortByLocationLat' },
  sortByLng: { attribute: 'sortByLocationLng' },
  lastActiveValue: { attribute: 'lastActiveValue' },
  lastActiveType: { attribute: 'lastActiveType' },
  externalCompanies: { attribute: 'externalCompanies' }
};

export const CANDIDATE_SORT_BY_OPTIONS = [
  { label: 'Best match', value: SearchSortByEnum.RankedRelevance },
  { label: 'Responsiveness', value: SearchSortByEnum.ResponseRate },
  { label: 'Posted date', value: SearchSortByEnum.DatePosted },
  { label: 'Last active date', value: SearchSortByEnum.LastActive },
  { label: 'Search term', value: SearchSortByEnum.Relevance }
];

const {
  lowerTimeOffset,
  upperTimeOffset,
  remoteWorking,
  remoteLocationContinents,
  remoteLocationCountries,
  lastActiveValue,
  lastActiveType
} = CANDIDATE_SEARCH_FILTERS;

export const TIME_OFFSET = {
  lowerTimeOffset: { value: -12, attribute: lowerTimeOffset.attribute },
  upperTimeOffset: { value: 12, attribute: upperTimeOffset.attribute }
};

export const SUGGESTED_FILTERS: CandidateSearchFilterKey[] = [
  'jobTitle',
  'location',
  'remoteWorking',
  'seniority',
  'orSkill',
  'salary'
];

export const WORKING_TIMEZONE_FILTERS: CandidateSearchFilterKey[] = [
  'lowerTimeOffset',
  'upperTimeOffset'
];

export const REMOTE_LOCATION_FILTERS: CandidateSearchFilterKey[] = [
  'remoteLocationContinents',
  'remoteLocationCountries'
];

export const LAST_ACTIVE_FILTERS: CandidateSearchFilterKey[] = [
  'lastActiveValue',
  'lastActiveType'
];

export const BOOLEAN_FILTERS: CandidateSearchFilterKey[] = [
  'workEligibility',
  'salaryVisible',
  'hidden',
  'previouslyDealtWith'
];

export const INVISIBLE_FILTERS: CandidateSearchFilterKey[] = [
  'boundEast',
  'boundSouth',
  'boundWest',
  'sortBy'
];

export const REMOTE_LOCATION_OPTIONS: SearchItems = [
  { label: 'UK', value: 'United Kingdom', attribute: remoteLocationCountries.attribute },
  { label: 'US', value: 'United States of America', attribute: remoteLocationCountries.attribute },
  { label: 'EU', value: 'Europe', attribute: remoteLocationContinents.attribute }
];

export const ATTRIBUTE_PAIR_MAP = {
  [lowerTimeOffset.attribute]: upperTimeOffset.attribute,
  [upperTimeOffset.attribute]: lowerTimeOffset.attribute,
  [lastActiveValue.attribute]: lastActiveType.attribute,
  [lastActiveType.attribute]: lastActiveValue.attribute
};

export const ATTRIBUTE_PARENT_MAP = {
  [remoteLocationContinents.attribute]: remoteWorking.attribute,
  [remoteLocationCountries.attribute]: remoteWorking.attribute
};

export const ATTRIBUTE_DEPENDENCY_MAP = {
  [remoteWorking.attribute]: [remoteLocationContinents.attribute, remoteLocationCountries.attribute]
};
