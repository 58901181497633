import { useEffect, useState } from 'react';
import { NavLink as Link, RouteComponentProps, useHistory } from 'react-router-dom';
import ClassNames from 'classnames';
import { TransitionState } from 'app';

import { FeedInterface } from '@cohiretech/common-types';

import { isMobileWidth, isTabletWidth } from 'responsiveConfig';
import { isCandidateProfile, isCompanyProfile, useCustomCSSVariables } from 'store';
import { useDarkMode } from 'hooks/useDarkMode';
import { CORD_LOGO, TOPNAV_HEIGHT } from 'consts';
import { useNavigatablePositionsStore } from 'store/navigatablePositions';

import { isFeatureEnabled } from 'v2/components/utility/FeatureToggle';
import ConditionalRender from 'v2/components/utility/ConditionalRender';
import CandidateTourBubble from 'v2/components/CandidateTourBubble';

import AdminMenu from './adminmenu';
import CandidateMenu from './candidatemenu';
import CompanyMenu from './companymenu';
import LandingMenu from './landingmenu';
import './style.scss';
import HomeLinkImage from './candidatemenu/HomeLinkImage';

const WhiteLogo = 'https://assets.co-hire.com/white-wordmark.svg';

export type LeftMenuProps = {
  bringTopNavForward: (bringForward: boolean) => void;
} & Omit<TopNavProps, 'setupMode'>;

const LeftMenu = ({ admin, profile, location, ...props }: LeftMenuProps) => {
  const darkMode = useDarkMode();
  const pathname = location.pathname;
  const isPublicNavPage = !(
    new RegExp('/company/').test(pathname) ||
    new RegExp('/candidate/').test(pathname) ||
    new RegExp('/admin/').test(pathname)
  );

  if (isCandidateProfile(profile)) {
    return <CandidateMenu />;
  } else if (isCompanyProfile(profile) && !isPublicNavPage) {
    return <CompanyMenu profile={profile} location={location} pathname={pathname} {...props} />;
  } else if (admin && !isPublicNavPage) {
    return <AdminMenu darkMode={darkMode} pathname={pathname} />;
  } else {
    return (
      <LandingMenu
        darkMode={darkMode}
        location={location}
        isPublicNavPage={isPublicNavPage}
        profile={profile}
        admin={admin}
        queryString={location?.search || ''}
      />
    );
  }
};

type TopNavProps = {
  onStreamsOrderUpdate: (streams: FeedInterface[]) => void;
  reloadCompanyStreams: () => Promise<void>;
  onPositionPause: (positionID: number, resume: boolean) => void;
  onPositionVisible: (positionID: number) => void;
  getCandidateData: () => Promise<void>;
  getCompanyData: () => Promise<void>;
  onMemberPositionsUpdate: (id: number) => void;
  darkMode: boolean;
} & RouteComponentProps &
  Omit<
    Required<TransitionState>,
    'loading' | 'integrationRedirectURL' | 'sessionEndedPopup' | 'showStartUsingCordPopup'
  >;

const TopNav = (props: TopNavProps) => {
  const { profile, admin, darkMode, setupMode, location, initialSetupMode, ...rest } = props;
  const [bringTopNavForward, setBringTopNavForward] = useState(false);
  const [fadein, setFadein] = useState(false);
  const history = useHistory();
  const { shouldShowNavigatablePositions } = useNavigatablePositionsStore();
  const [, setCustomCSSVariable] = useCustomCSSVariables();

  useEffect(() => {
    setCustomCSSVariable('topnav-height', `${TOPNAV_HEIGHT}px`, 'public');
  }, []);

  useEffect(() => {
    const doFadein = () => window.scrollY > 100 || (window.scrollY > 40 && isMobileWidth);

    window.addEventListener('scroll', () => {
      if (fadein !== doFadein()) setFadein(!fadein);
    });

    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, [fadein]);

  const handleBringTopNavForward = (bringForward: boolean) => {
    setBringTopNavForward(bringForward);
  };

  const closeSignup = () => {
    const isSignupPath =
      new RegExp('/signup').test(location.pathname) ||
      new RegExp('/sign-up').test(location.pathname);

    if (isSignupPath && location.key === undefined) history.push({ pathname: '/', hash: '' });
    else if (isSignupPath) history.goBack();
    else history.push({ pathname: location.pathname, hash: '' });
  };

  const { pathname } = location;
  const isPublicNavPage = !(
    new RegExp('/company/').test(pathname) ||
    new RegExp('/candidate/').test(pathname) ||
    new RegExp('/admin/').test(pathname)
  );
  const isCandidate = profile && profile.isCandidate;
  const isSignup = new RegExp('/signup').test(pathname) || new RegExp('/sign-up').test(pathname);
  const landedOnPage = location.key === undefined && !shouldShowNavigatablePositions;

  const pagesToHideTopNavFrom =
    new RegExp('/company/messages').test(pathname) ||
    new RegExp('/company/setup/positions').test(pathname) ||
    new RegExp('/company/setup/finish').test(pathname) ||
    new RegExp('/company/setup/review-profile').test(pathname) ||
    new RegExp('/company/setup/complete-profile').test(pathname) ||
    new RegExp('/admin/candidate/search').test(pathname) ||
    new RegExp('/admin/candidate/verify').test(pathname) ||
    new RegExp('/admin/candidate/waitlisted').test(pathname) ||
    new RegExp('/admin/candidate/rejected').test(pathname) ||
    new RegExp('/admin/company/verify').test(pathname) ||
    new RegExp('/admin/candidate/cv').test(pathname);

  const surveyPage = new RegExp('/survey').test(pathname);

  return (
    <header
      id="topnav"
      className={ClassNames({
        setup_mode: setupMode || initialSetupMode,
        bring_forward: bringTopNavForward,
        signup: isSignup || new RegExp('/signup').test(pathname),
        public: isPublicNavPage,
        candidate: isCandidate,
        company: profile && !profile.isCandidate,
        admin,
        hide: new RegExp('/u/').test(pathname) && !landedOnPage,
        hidden: pagesToHideTopNavFrom,
        dark: darkMode,
        insights: pathname.includes('/techhub/') || pathname.includes('/hiring-insights/'),
        hiring: pathname.match(/\/companies($|\/$|\/invite)/) || pathname.includes('/agencies'),
        company_messages:
          new RegExp('/company/messages').test(pathname) &&
          isFeatureEnabled({
            featureName: 'new_inbox',
            // @ts-expect-error: profile type needs narrowing
            preferences: profile?.companyUser?.preferences
          }),
        fadein
      })}
    >
      <div
        className={ClassNames({
          topnav_width: (profile === null && admin === null) || isCandidate || isPublicNavPage,
          small_width: surveyPage
        })}
      >
        {((profile === null && admin === null) || isCandidate || isPublicNavPage) && (
          <Link
            id="topnav_left_menu"
            to={isCandidate ? '/candidate/discover' : '/'}
            onClick={e => {
              if (
                (e.target as $TSFixMe).className &&
                (e.target as $TSFixMe).className.includes !== undefined &&
                (e.target as $TSFixMe).className.includes('bubble')
              ) {
                e.preventDefault();
              }
            }}
          >
            <ConditionalRender predicate={!isCandidate} fallback={<HomeLinkImage />}>
              <img
                src={isSignup && !isTabletWidth ? WhiteLogo : CORD_LOGO}
                id="logo"
                alt="cord logo"
                height="40"
              />
            </ConditionalRender>
            <strong className="title">cord</strong>
            <CandidateTourBubble bubbleName="discover" align="left" centeredOnMobile />
          </Link>
        )}
        {!surveyPage && (
          <div id="topnav_right_menu">
            <LeftMenu
              profile={profile}
              admin={admin}
              darkMode={darkMode}
              location={location}
              bringTopNavForward={(bringForward: $TSFixMe) =>
                handleBringTopNavForward(bringForward)
              }
              initialSetupMode={initialSetupMode}
              {...rest}
            />
          </div>
        )}
        {isSignup && <span className="icon_close" onClick={() => closeSignup()} />}
      </div>
    </header>
  );
};

export default TopNav;
