import React, { useEffect, useState, PropsWithChildren } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { StringOrElements } from 'types';

import colors from 'css/base/_colours.module.scss';
import typography from 'css/base/_typography.module.scss';

export type Props = {
  className?: string;
  text?: StringOrElements;
  appearance?: 'info_light' | 'info' | 'warning' | 'error';
  icon?: string;
  show?: boolean;
  onHide?: () => void;
  nowrap?: boolean;
  fitContent?: boolean;
};

function Banner({
  className,
  text,
  appearance = 'info',
  icon = 'icon_tip',
  show = true,
  onHide,
  nowrap,
  fitContent,
  children
}: PropsWithChildren<Props>) {
  const [backgroundColor, setBackgrnd] = useState(colors.semiTransparentPrimaryColour);
  const [fontColor, setFontColour] = useState(colors.primaryColour);

  useEffect(() => {
    const bgColors = {
      info: colors.semiTransparentPrimaryColour,
      error: colors.errorSemiTransparentShade,
      warning: colors.semiTransparentWarningColour,
      info_light: colors.transparentPrimaryColour
    };
    const fontColors = {
      info: colors.primaryColour,
      error: colors.errorColour,
      warning: colors.warningColour,
      info_light: colors.primaryColour
    };
    setBackgrnd(bgColors[appearance]);
    setFontColour(fontColors[appearance]);
  }, []);

  return (
    <BannerWrapper
      className={ClassNames('banner', className, { hidden: !show }, { nowrap })}
      aria-hidden={!show}
      show={show}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      fitContent={fitContent}
    >
      <span id="icon_type" className={ClassNames('icon', icon)} />
      {(text || children) && (
        <Text data-testid="text" className={ClassNames('banner_text', { nowrap })}>
          {text || children}
        </Text>
      )}
      {onHide && <span tabIndex={0} role="button" className="icon icon_close" onClick={onHide} />}
    </BannerWrapper>
  );
}

type BannerWrapperProps = {
  backgroundColor: string;
  fontColor: string;
  show?: boolean;
  fitContent?: boolean;
};

const BannerWrapper = styled.div<BannerWrapperProps>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 15px;
  color: ${({ fontColor }) => fontColor};
  font-size: 16px;
  font-weight: ${typography.semibold};
  padding: 5px 10px;
  display: ${({ show }) => (show ? 'flex' : 'none')};

  &.nowrap {
    overflow: hidden;
    border-radius: 10px;
  }

  .icon {
    align-self: center;
    margin-right: 5px;
  }

  .icon_close {
    margin-left: auto;
    padding: 18px;
  }

  ${({ fitContent }) =>
    fitContent &&
    css`
      width: fit-content;
      margin: 0 auto;
      border-radius: var(--radius-md);
      padding: var(--spacing-03) var(--spacing-04);
      align-items: center;
      gap: var(--spacing-02);
    `}
`;

const Text = styled.div`
  text-align: left;

  &.nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default Banner;
