import React, {
  ComponentType,
  lazy,
  Suspense,
  TouchEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import ClassNames from 'classnames';
import { RouteComponentProps, useHistory, useLocation, useParams } from 'react-router-dom';
import { generateJobPostingSchema } from 'schema/jobposting';
import { candidateClearForm } from 'candidateSignupForm';
import { swipeXAxisOnStart, swipeXAxisOnMove, swipeXAxisOnEnd } from 'swipeControl';

import {
  CompanyInterface,
  ApplicationInterface,
  CandidateSignupVerificationStatus as CSVS,
  CandidateTracking,
  SearchTrackingEnum
} from '@cohiretech/common-types';

import {
  CompanyProfile,
  CandidateProfile,
  PositionInProfile,
  ExtendedApplicationDetails
} from 'types';
import * as fetcher from 'fetcher';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'cookieManager';
import {
  generateCompanyURL,
  generatePersonsURL,
  generatePositionURL,
  getPositionButtonNote,
  retry,
  isObjectEmpty,
  removeTag,
  getQueryStringObject,
  getBlockedMessage,
  getReferrerTrackingDetails,
  addURLProtocol
} from 'utils';
import { capitalizeSentences } from 'utils/string';
import {
  trackSignupClicks,
  trackSalaryToolCTAClicks,
  getPageTrackingContext
} from 'tracking-utils';
import {
  getProfileShareURL,
  checkIfProfileShareURL,
  checkIfJobAdvertistingSource,
  storeApplicationAction
} from 'utils/candidateUtils';
import { usePrevious } from 'hooks/usePrevious';
import useLockedScroll from 'hooks/useLockScroll';
import { useDarkMode } from 'hooks/useDarkMode';
import { appendQueryParam } from 'utils/url/appendQueryParams';
import { getStatusBeforeLive } from 'v2/services/candidate';
import { identifyCompanyType } from 'v2/services/company/companyType';
import { hideScrollbar } from 'v2/services/ui';
import { isCandidateProfile, ProfileState } from 'store';
import useEventListener from 'hooks/useEventListener';
import { mediaQueries } from 'responsiveConfig';
import useMedia from 'hooks/useMedia';
import { trackSearchView } from 'v2/services/fetchers/candidate/tracking';
import { useNavigatablePositionsStore } from 'store/navigatablePositions';
import { checkIfApplied } from 'v2/services/tools/application';
import { isNumber } from 'utils/fn';

import { useNavigatablePositionsContext } from 'v2/pages/candidate/NavigatablePositions';
import { NavigatableXPosition } from 'v2/pages/candidate/NavigatablePositions/NavigatablePositions.helpers';

import LabelWithIcon from 'components/labelwithicon';
import CompanyProfilePersonInfoContainer from 'components/companyprofile/personinfocontainer';
import Loader from 'components/loader';
import Seo from 'components/seo';
import Tooltip from 'components/tooltip';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { useMarkApplicationPopup } from 'v2/components/MarkApplicationPopup';

import PositionProfileTopNav from './PositionProfileTopNav';
import ButtonPanel from './buttonpanel';
import { getApplyButtonTooltip, getPositionViewElement, isListingInvisible } from './helpers';

import './style.scss';

const PositionProfile = lazy(
  () =>
    retry(() => import('components/positionprofile')) as Promise<{ default: ComponentType<any> }>
);
const noIndexPositions = process.env.REACT_APP_NO_INDEX_POSITIONS?.split(' ') || [];

export type ApplicationDetailsLocationState = {
  apply: boolean;
  origin?: string;
  title?: string;
  positionName?: string;
  companyName?: string;
  person?: string;
  jobTitle?: string;
};

export type PositionType = PositionInProfile &
  CompanyInterface & {
    associatedMemberDetails?: any;
    applicationDetails?: ExtendedApplicationDetails;
    shortlistID?: any;
    shortlistType?: any;
    companyInvisible?: any;
    listingInvisible?: any;
    companyHasBlocked?: boolean;
    candidateHasBlocked?: boolean;
    external: boolean;
    externalURL?: string;
    datePosted?: Date;
  };

export interface PositionProfileViewProps extends RouteComponentProps<any> {
  profile: ProfileState;
  candidateProfile: CandidateProfile | null;
  admin: any;
  onPositionChange: (id: number) => void;
  className?: string;
  navigatablePositionID?: number;
  xPosition?: NavigatableXPosition;
}

export default function PositionProfileView({
  profile,
  match,
  candidateProfile,
  admin,
  onPositionChange,
  className,
  navigatablePositionID,
  xPosition,
  ...props
}: PositionProfileViewProps) {
  const [loading, setLoading] = useState(true);
  const [positionState, setPositionState] = useState({} as PositionType);
  const [showButtonPanel, setShowButtonPanel] = useState(false);
  const location = useLocation();
  const [landedFromExternalURL, setLandedFromExternalURL] = useState(location.key === undefined);
  const [profileHistoryLength, setProfileHistoryLength] = useState(1); // For handling the back and close icons
  const [errorMessage, setErrorMessage] = useState('');
  const darkMode = useDarkMode();
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isMobile = useMedia([mediaQueries.mobile]);
  const isOutOfNavigatableIndex = xPosition && xPosition !== 'middle' && !navigatablePositionID;
  const { id: paramID } = useParams<{ id: string }>();
  const parsedParamId = paramID ? Number(paramID) : -1;

  const {
    cachedNavigatablePositions,
    cachedNavigatablePositionsMetadata,
    setNavigatablePositionIDs,
    cacheNavigatablePosition,
    clearCachedNavigatablePositions,
    cacheNavigatablePositionMetadata
  } = useNavigatablePositionsStore();

  const { navigateToPrevious, navigateToNext } = useNavigatablePositionsContext();
  const { showMarkApplicationPopup } = useMarkApplicationPopup();

  useLockedScroll(!navigatablePositionID);

  const didMountRef = useRef(false);
  const prevLocation = usePrevious(location);
  const prevXPosition = usePrevious(xPosition);

  const isCompanyInterested = (application: ApplicationInterface) => {
    const { companyInterested, candidateInterested } = application;

    return companyInterested && (candidateInterested === undefined || candidateInterested === null);
  };

  const closeProfile = () => {
    const pathname = `${location.pathname.substring(0, location.pathname.indexOf('/u/'))}`;

    if (location.search) history.push({ pathname, hash: location.hash, search: location.search });
    else history.push(pathname);

    removeLocalStorageItem('profileHistoryLength');
    clearCachedNavigatablePositions();
    setNavigatablePositionIDs([]);
  };

  const showChatBubble = (listingID: number, companyID: number) => {
    // open chat
    history.push({
      pathname: location.pathname,
      search: `?chat=${listingID},${companyID}&status=open`
    });
  };

  const openMessageThread = async (toggleChat: boolean = true) => {
    const { associatedMemberDetails, id, companyID } = positionState;

    const applyDetails = {
      listingID: id,
      companyID,
      companyAccountID: associatedMemberDetails.accountID
    };

    const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';
    const jobSearchPosition = getLocalStorageItem('jobSearchPosition') || 0;

    const { status, message } = await fetcher.startApplication(
      applyDetails,
      uniqueIdentifier,
      jobSearchPosition
    );

    if (status === 'success') {
      if (toggleChat) showChatBubble(applyDetails.listingID, applyDetails.companyID);

      onPositionChange(applyDetails.listingID!);

      setErrorMessage('');
    } else {
      setErrorMessage(message.message ? capitalizeSentences(message.mesage) : '');
    }
  };

  const onMessage = async () => {
    const { external, externalURL, applicationDetails } = positionState;
    const { rejected } = candidateProfile || {};
    const { applicationCandidateStartStatus } = applicationDetails || {};

    if (rejected) {
      window.open(positionState.url!, '_blank');
      return;
    }

    openMessageThread(!external);

    if (!external || !externalURL) return;

    const modifiedURL = appendQueryParam(addURLProtocol(externalURL, true), {
      param: 'utm_source',
      value: 'cord'
    });
    window.open(modifiedURL, '_blank');

    if (!checkIfApplied(applicationCandidateStartStatus)) showMarkApplicationPopup('prompt');
  };

  const handleShowingButtonPanel = () => {
    const chatBubbleOpened = location.search && location.search.includes('?chat=');

    if (!chatBubbleOpened) setTimeout(() => setShowButtonPanel(true), 300);
  };

  const updatePosition = async () => {
    if (isOutOfNavigatableIndex) return;

    const positionID = navigatablePositionID || parsedParamId;
    const uniqueIdentifier = getLocalStorageItem('uniqueIdentifier') || '';
    const jobSearchPosition = getLocalStorageItem('jobSearchPosition') || 0;
    const searchPositionModifier = xPosition === 'middle' ? 0 : xPosition === 'left' ? -1 : 1;
    // correct the searchposition depending on xPosition. also sets a min of 0
    const parsedJobSearchPosition = Math.max(jobSearchPosition + searchPositionModifier, 0);
    const profileHistoryLength = getLocalStorageItem('profileHistoryLength') || 1;

    if (positionID > -1 && positionState.id !== positionID) {
      if (!loading) setLoading(true);

      const doNotTrack = Boolean(navigatablePositionID && xPosition !== 'middle');

      const newPosition =
        navigatablePositionID && cachedNavigatablePositions[positionID]
          ? cachedNavigatablePositions[positionID]
          : await fetcher.getPosition(
              positionID,
              candidateProfile?.isCandidate || false,
              uniqueIdentifier,
              jobSearchPosition,
              doNotTrack
            );

      if (!newPosition) closeProfile();
      else {
        if (
          newPosition.status === 'active' &&
          newPosition.advertisingHidden === false &&
          (!xPosition || xPosition === 'middle') // when user land on a position page xPosition is undefined
        ) {
          generateJobPostingSchema(newPosition, location.pathname);
        }

        if (navigatablePositionID && !cachedNavigatablePositions[positionID]) {
          cacheNavigatablePosition(newPosition);
          cacheNavigatablePositionMetadata({
            id: newPosition.id,
            ui: uniqueIdentifier,
            search_position: parsedJobSearchPosition,
            type: SearchTrackingEnum.Listing,
            companyID: newPosition.companyID
          });
        }
        setPositionState(newPosition);
        setLandedFromExternalURL(location.key === undefined);
        setProfileHistoryLength(profileHistoryLength);
        setLoading(false);

        if (
          newPosition?.applicationDetails &&
          isCompanyInterested(newPosition.applicationDetails)
        ) {
          await fetcher.seenPosition(positionID, newPosition.companyID);
        }

        handleShowingButtonPanel();
      }
    } else closeProfile();
  };

  const handleKeydown = (event: Event | KeyboardEvent) => {
    const dontHandle =
      (event.target as HTMLElement).getAttribute('id') === 'message_template' ||
      xPosition !== 'middle' ||
      !scrollRef.current;

    if (dontHandle) return;

    switch ((event as KeyboardEvent).key) {
      case 'ArrowUp':
        scrollRef.current.scrollBy(0, -100);
        break;
      case 'ArrowDown':
        scrollRef.current.scrollBy(0, 100);
        break;
      default:
        break;
    }
  };

  useEventListener('keydown', handleKeydown);

  useEffect(() => {
    hideScrollbar(true);
    updatePosition();

    const helpkitWidget = document.getElementById('helpkit-launcherButton--cordpeople');
    const hideHelpKitWidgetOnMobile = navigatablePositionID && isMobile && helpkitWidget;

    if (hideHelpKitWidgetOnMobile) {
      helpkitWidget.style.display = 'none';
    }

    return () => {
      removeTag('script', 'id', 'job_posting');

      hideScrollbar(false);

      if (hideHelpKitWidgetOnMobile) {
        helpkitWidget.style.display = 'flex';
      }
    };
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      if (!navigatablePositionID && location.pathname !== prevLocation.pathname) {
        updatePosition();
        getPositionViewElement().scrollTop = 0;
      }

      // Handles data tracking for navigatable positions when they slide into view
      if (
        isCandidateProfile(profile) &&
        xPosition !== prevXPosition &&
        navigatablePositionID &&
        xPosition === 'middle'
      ) {
        fetcher.trackCandidateAction(CandidateTracking.ViewListing);
        const metadata = cachedNavigatablePositionsMetadata[navigatablePositionID];
        if (metadata) {
          trackSearchView({
            ui: metadata.ui,
            id: metadata.id,
            type: metadata.type,
            search_position: metadata.search_position
          });
        } else {
          // if the next profile has yet to be loaded, metadata may be null
          // in this case, calculate the fallback metadata
          const key = parseInt(Object.keys(cachedNavigatablePositionsMetadata)[0], 10);
          if (isNumber(key)) {
            const fallbackMetadata = cachedNavigatablePositionsMetadata[key];
            trackSearchView({
              ui: fallbackMetadata.ui,
              id: navigatablePositionID,
              type: fallbackMetadata.type,
              search_position: fallbackMetadata.search_position + 1
            });
          }
        }
      }
    } else didMountRef.current = true;
  }, [location, xPosition]);

  useEffect(() => {
    if (positionState.id === parsedParamId) {
      setPositionState(cachedNavigatablePositions[parsedParamId]);
    }
  }, [cachedNavigatablePositions[parsedParamId]]);

  const onShortlisted = (
    listingID: number,
    shortlistID: number | null,
    shortlistType: string | null
  ) => {
    const positionCopy = { ...positionState };

    positionCopy.shortlistID = shortlistID;
    positionCopy.shortlistType = shortlistType;

    setPositionState(positionCopy);

    onPositionChange(listingID);
  };

  const publicApplyRequest = (isSalaryToolLandingPage: boolean, isJobAdvertisingUser: boolean) => {
    const {
      id,
      companyID,
      associatedMemberDetails: { memberName, accountID },
      companyName,
      position
    } = positionState;

    candidateClearForm();
    storeApplicationAction({
      listingID: id,
      companyID,
      companyAccountID: accountID,
      companyName,
      positionName: position,
      personsName: memberName
    });

    history.push({
      pathname: isSalaryToolLandingPage ? '/salary-tool-signup' : '/signup',
      state: {
        origin: 'apply_button',
        title: isJobAdvertisingUser
          ? `Apply to ${companyName}`
          : `Start speaking with ${memberName}`,
        companyName,
        positionName: position,
        person: memberName,
        jobTitle
      } as ApplicationDetailsLocationState,
      search: `?utm_medium=application&utm_content=${positionState.position} at ${positionState.companyName}`
    });
  };

  const login = () => {
    window.location.href = '/login';
  };

  const goToProfile = () => {
    const {
      associatedMemberDetails: { accountID, memberName },
      companyName = '',
      external
    } = positionState;
    if (external) {
      history.push(companyURL);
      return;
    }
    const personsData = { companyName, personsName: memberName, personsID: accountID };

    const personsURL = personsData ? generatePersonsURL(location, personsData) : '';

    setLocalStorageItem('profileHistoryLength', profileHistoryLength + 1);
    history.push(personsURL);
  };

  const handleClickView = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    if (target.id === 'position_view') closeProfile();
  };

  const publicAction = (isJobAdvertisingUser: boolean) => {
    const { pathname, hash } = location;
    const isSalaryToolLandingPage = new RegExp('/salary-tool').test(pathname);

    const { referrerDetails } = getReferrerTrackingDetails();
    setLocalStorageItem('referrerDetails', {
      ...referrerDetails,
      clickedPublicPositionPageCTA: true
    });

    trackSignupClicks('Public position page start speaking', {}, getPageTrackingContext(location));
    if (isSalaryToolLandingPage) trackSalaryToolCTAClicks('SBT - Position page start speaking');

    if (hash === '#email' || hash === '#newposition') login();
    else publicApplyRequest(isSalaryToolLandingPage, isJobAdvertisingUser);
  };

  const { pathname, hash, search } = location;
  const positionInfo = positionState;
  const inMessagingPage = pathname.startsWith('/candidate/messages');
  const isCompanyUser = (profile as CompanyProfile)?.companyID;

  const getSwipeProps = () => {
    return navigatablePositionID
      ? {
          onTouchStart: swipeXAxisOnStart,
          onTouchMove: swipeXAxisOnMove,
          onTouchEnd: (e: TouchEvent) => swipeXAxisOnEnd(e, navigateToNext, navigateToPrevious)
        }
      : {};
  };

  const {
    id,
    companyName = '',
    responseTime,
    position,
    companyID,
    associatedMemberDetails,
    pictureUrls,
    companyLogo,
    applicationDetails,
    status,
    companyInvisible,
    listingInvisible,
    companyHasBlocked,
    candidateHasBlocked,
    external,
    companyType,
    shortlistID,
    shortlistType
  } = positionInfo;
  const positionID = navigatablePositionID || id;
  const { memberName, jobTitle, accountID, photoURL } = associatedMemberDetails || {};
  const companyData = { companyName, companyID };
  const personsData = { companyName, personsName: memberName, personsID: accountID };

  const companyURL = generateCompanyURL(location, companyData);
  const personsURL = !external ? generatePersonsURL(location, personsData) : '';
  const shareURL = getProfileShareURL(pathname, (profile as CandidateProfile)?.firstName);
  const applicationExists = applicationDetails && !isObjectEmpty(applicationDetails);

  const buttonNote = applicationExists
    ? getPositionButtonNote(applicationDetails, companyName)
    : {};

  const { utm_source, utm_referrer } =
    (getQueryStringObject(search) as { [key: string]: string }) || {};
  const isJobAdvertisingUser = checkIfJobAdvertistingSource(search);
  const isProfileShareURL = checkIfProfileShareURL(utm_source, utm_referrer);
  const pageTitle = isProfileShareURL
    ? `${utm_referrer} shared ${companyName} ${position} with you`
    : `${position} at ${companyName}`;
  const applyButtonTooltip = getApplyButtonTooltip(profile, positionInfo);

  const { isDirect } = identifyCompanyType(companyType);
  const showAsLandingPage = landedFromExternalURL && !navigatablePositionID;

  if (isOutOfNavigatableIndex) return null;

  return (
    <div
      id="position_view"
      className={ClassNames(
        {
          admin,
          landing_page: showAsLandingPage,
          dark: darkMode,
          candidate_logged_in: profile?.isCandidate,
          company_logged_in: isCompanyUser
        },
        className
      )}
      onClick={e => handleClickView(e)}
      ref={scrollRef}
      {...getSwipeProps()}
    >
      <Suspense
        fallback={<Loader className={ClassNames('position_loader', 'large', { dark: darkMode })} />}
      >
        <div className="position_dialog profile_view">
          <PositionProfileTopNav
            loading={loading}
            companyName={companyName}
            position={position}
            companyURL={companyURL}
            isDirect={isDirect}
            profileHistoryLength={profileHistoryLength}
            showAsLandingPage={showAsLandingPage}
            closeProfile={closeProfile}
          />
          <div
            className={ClassNames('position_content', 'show_bottom_arrow', {
              dark: darkMode,
              external_listing: external
            })}
          >
            <PositionProfile
              companyURL={companyURL}
              personsURL={personsURL}
              personsName={memberName}
              personsPhotoURL={photoURL}
              {...positionInfo}
              {...props}
              match={match}
              onPositionChange={onPositionChange}
              loading={loading}
              positionLocation={positionInfo.location}
              profile={profile}
              onProfileClose={() => closeProfile()}
              onShortlisted={(l: number, s: number, t: string) => onShortlisted(l, s, t)}
              onMessage={() => onMessage()}
              goToSignup={() => history.push('/signup')}
              goToProfile={goToProfile}
              updateProfileHistory={() =>
                setLocalStorageItem('profileHistoryLength', profileHistoryLength + 1)
              }
              shareURL={shareURL}
            />
          </div>
          <ConditionalRender
            predicate={memberName && jobTitle && !external && !navigatablePositionID}
          >
            <div className="person_container">
              <CompanyProfilePersonInfoContainer
                {...associatedMemberDetails}
                name={memberName}
                companyName={companyName}
                companyLogo={companyLogo}
                onClick={goToProfile}
              />
            </div>
          </ConditionalRender>
          <ConditionalRender
            predicate={!inMessagingPage && !admin && !isCompanyUser && positionInfo?.id}
          >
            <ButtonPanel
              className={ClassNames('profile_view', {
                show: showButtonPanel,
                dark: darkMode
              })}
              blockNote={
                getBlockedMessage(
                  isCompanyUser,
                  companyHasBlocked,
                  candidateHasBlocked,
                  companyName,
                  candidateProfile?.firstName
                ) ||
                (status === 'archived' ||
                companyInvisible ||
                isListingInvisible(positionID, listingInvisible)
                  ? `${companyName} are no longer hiring for this position.`
                  : [])
              }
              //@ts-ignore
              applicationNote={
                applicationExists ? (
                  <LabelWithIcon
                    text={buttonNote?.note}
                    icon={buttonNote?.icon || ''}
                    color="grey"
                    bold
                  >
                    <span className="time_ago">{buttonNote?.time}</span>
                  </LabelWithIcon>
                ) : undefined
              }
              primaryButton={{
                text: getMessageButtonText(
                  !candidateProfile?.rejected,
                  external,
                  applicationExists
                ),
                action:
                  applicationExists && !external
                    ? () => showChatBubble(positionID, companyID)
                    : () => onMessage(),
                icon: getApplyIcon(external, applicationExists),
                iconPosition: 'right',
                tooltip: applyButtonTooltip && <Tooltip width="260px">{applyButtonTooltip}</Tooltip>
              }}
              publicButton={{
                text: getPublicButtonText(hash, memberName, companyName!, isJobAdvertisingUser),
                action: () => publicAction(isJobAdvertisingUser)
              }}
              companyURL={companyURL}
              personsURL={personsURL}
              updateProfileHistory={() =>
                setLocalStorageItem('profileHistoryLength', profileHistoryLength + 1)
              }
              userLoggedIn={!!candidateProfile?.id}
              personDetails={associatedMemberDetails}
              companyName={companyName}
              accountBeingVerified={getStatusBeforeLive(candidateProfile) === CSVS.Pending}
              responseTime={responseTime}
              errorMessage={errorMessage}
              companyType={companyType}
              companyLogo={positionInfo.companyLogo}
              companyPosition={positionInfo.position}
              shortlistType={shortlistType}
              shortlistID={shortlistID}
              onShortlisted={(s: number | null, t: string | null) =>
                onShortlisted(positionID, s, t)
              }
              positionID={positionID}
              xPosition={xPosition}
            />
          </ConditionalRender>
        </div>
      </Suspense>
      {positionInfo?.id && (
        <Seo
          title={pageTitle}
          description={`${companyName} is hiring a ${position}. Join cord to speak directly with ${memberName}, ${jobTitle} at ${companyName}.`}
          path={generatePositionURL(
            location,
            { companyName: companyName!, positionID, positionName: position },
            true
          )}
          contentType="website"
          image={pictureUrls![0]}
          robots={
            status === 'archived' || noIndexPositions.includes(positionID.toString()) ? 'none' : ''
          }
        />
      )}
    </div>
  );
}

const getMessageButtonText = (
  isMessagingAllowed: boolean,
  externalListing: boolean,
  applicationExists?: boolean
): string => {
  if (externalListing) return applicationExists ? 'Go to careers page' : 'Apply externally';
  if (!isMessagingAllowed) return 'Go to website';
  return applicationExists ? 'Open chat' : 'Send direct message';
};

const getApplyIcon = (externalListing: boolean, applicationExists?: boolean): string => {
  if (externalListing) return applicationExists ? 'icon_preview' : 'icon_apply';
  return applicationExists ? 'icon_chat' : 'icon_apply';
};

const getPublicButtonText = (
  hash: string,
  memberName: string,
  companyName: string,
  isJobAdvertisingUser: boolean
) => {
  if (hash === '#email') return 'Log in to view the message';
  if (hash === '#newposition') return `Log in to message ${memberName}`;
  if (isJobAdvertisingUser) return `Apply to ${companyName}`;
  return `Message ${memberName}`;
};
